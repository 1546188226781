import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import '../home/Home.css'; // Reusing the same CSS for consistency

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($id: String!, $newPassword: String!, $token: String!) {
    resetPassword(userID: $id, newPassword: $newPassword, token: $token)
  }
`;

export const PasswordReset = () => {
    const { id, token } = useParams<{ id: string; token: string }>();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD_MUTATION);
    const [isResetSuccessful, setIsResetSuccessful] = useState(false);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword === confirmPassword && newPassword.length) {
            try {
                resetPassword({
                    variables: { id, newPassword, token },
                }).then(() => {
                    setIsResetSuccessful(true);
                }).catch((e) => {
                    console.log(e);

                });
            } catch (e) {
                console.log('Error resetting password:', e);
            }
        }
    };

    return (
        <div className="Home">
            <div className="PasswordReset-body">
                {isResetSuccessful ? (
                    <div>Password has been successfully reset</div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        {loading ? (
                            <div className="loader"></div>
                        ) : (
                            <button type="submit" disabled={newPassword !== confirmPassword || !newPassword}>
                                Submit
                            </button>
                        )}
                    </form>
                )}
                {error && !isResetSuccessful && <p className="PasswordReset-error">Error resetting password. Please try again.</p>}
            </div>
        </div>
    );
};
