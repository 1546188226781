// VerifyEmail.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from "@apollo/client";
import '../home/Home.css';

enum VerificationStatus {
    Verifying = 'verifying',
    Success = 'success',
    Failed = 'failed',
}

const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($userID: String!, $token: String!) {
    verifyEmail(userID: $userID, token: $token)
  }
`;


export const VerifyEmail = () => {
    const [status, setStatus] = useState<VerificationStatus>(VerificationStatus.Verifying);
    const navigate = useNavigate();
    const { id, token } = useParams<{ id: string; token: string }>();
    const [verifyEmail] = useMutation(VERIFY_EMAIL_MUTATION);

    // Try to verify email when landing on this page
    useEffect(() => {
        verifyEmail({ variables: { userID: id, token: token } }).then(() => {
            navigate('/emailVerified');
        }).catch((e) => {
            setStatus(VerificationStatus.Failed);
            console.log("Email verification failed: ", e);
        });
    }, []);

    return (
        <div className="Home">
            <header className="Home-header">
                {status === VerificationStatus.Verifying && (
                    <>
                        <h1>Verifying Email</h1>
                        <div className="loader"></div>
                    </>
                )}
                {status === VerificationStatus.Failed && (
                    <>
                        <h1>Email Verification Failed</h1>
                        <div className="error-indicator">X</div>
                    </>
                )}
            </header>
        </div>
    );
};
