// EmailVerified.tsx
import React from 'react';
import '../home/Home.css'; // Reuse styles from Home

export const UserDeleted = () => {
    return (
        <div className="Home">
            <header className="Home-header">
                <h1>Account removed</h1>
                <div className="checkmark-container">
                    ✓ {/* You might want to replace this with an icon for better visuals */}
                </div>
            </header>
        </div>
    );
};
