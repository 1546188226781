import React from 'react';
import { Home } from  './home/Home'
import { VerifyEmail } from "./verifyEmail/VerifyEmail";
import { EmailVerified } from "./emailVerified/EmailVerified";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PasswordReset } from "./resetPassword/ResetPassword";
import { DeleteUser } from './deleteUser/DeleteUser';
import { UserDeleted } from './userDeleted/UserDeleted';
import { Header } from "./shared/Header";
import { MaxWidth } from "./constants";
import './App.css';
import { Footer } from "./shared/Footer";
import { Privacy } from "./privacy/Privacy";
import { TermsAndConditions } from "./terms/TermsAndConditions";

export const App = () => {
    return (
        <BrowserRouter>
            <div style={{ backgroundColor: '#9881BDFF' }}>
                <Header />
                <div className="app-container" style={{ maxWidth: MaxWidth }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="verifyEmail/:id/:token" element={<VerifyEmail />} />
                            <Route path="/emailVerified" element={<EmailVerified />} />
                            <Route path="/userDeleted" element={<UserDeleted />} />
                            <Route path="/resetPassword/:id/:token" element={<PasswordReset />} />
                            <Route path="/deleteUser/:id/:token" element={<DeleteUser />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/tc" element={<TermsAndConditions />} />
                        </Routes>
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    );
}
