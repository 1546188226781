import React from "react";
import './Home.css';
import birb from '../assets/birb.png';
import bookingMock from '../assets/booking_mock.png';
import homeMock from '../assets/home_mock.png';

export const Home = () => {
    return (
        <div className="Home">
            <header className="Home-header">
                <div className="content">
                    <div className="text-container">
                        <h2>Text Securely.<br/>Earn Effortlessly.<br/>Connect Meaningfully.</h2>
                        <h3 className="coming-soon">Coming Soon</h3>
                    </div>
                    <div className="image-container">
                        <img src={birb} className="birb" alt="birb"/>
                        <div className="mock-images">
                            <img src={bookingMock} className="mock" alt="booking mock"/>
                            <img src={homeMock} className="mock" alt="home mock"/>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};
