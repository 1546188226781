// src/apolloClient.js
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';

// TODO - This should be passed in as an ENV variable
const httpLink = new HttpLink({
    uri: 'https://birbli-users.azurewebsites.net/graphql?code=E2lZlcBerGw4uwfCFDRWTGOlSCVLSMTOptSmZYXwuq5xAzFud6OWTQ%3D%3D',
    headers: {
        'Content-Type': 'application/json',
    },
});

const authLink = new ApolloLink((operation, forward) => {
    return forward(operation);
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
