import React from "react";
import { Link } from "react-router-dom";
import './Header.css';
import logo from '../assets/birbli_logo.png';
import { MaxWidth } from "../constants";

export const Header = () => {
    return (
        <header className="header">
            <div className="logo-container" style={{ maxWidth: MaxWidth }}>
                <Link to="/" className="logo-link" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <img src={logo} alt="birbli logo" className="logo" />
                    <span className="company-name">Birbli</span>
                </Link>
            </div>
        </header>
    );
};
