import React from "react";
import './Footer.css';
import { MaxWidth } from "../constants";
import {Link} from "react-router-dom";

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content" style={{ maxWidth: MaxWidth }}>
                <div className="footer-links">
                    <Link to={ { pathname: '/privacy' } } className="footer-link">Privacy Policy</Link>
                    <Link to={ { pathname: '/tc' } } className="footer-link">Terms and Conditions</Link>
                </div>
                <div className="footer-company">
                    © 2024 Birbli LLC
                </div>
            </div>
        </footer>
    );
};
