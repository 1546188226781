// VerifyEmail.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from "@apollo/client";
import '../home/Home.css';

enum DeleteUserStatus {
    Pending = 'pending',
    Success = 'success',
    Failed = 'failed',
}

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userID: String!, $token: String!) {
    deleteUser(userID: $userID, token: $token)
  }
`;


export const DeleteUser = () => {
    const [status, setStatus] = useState<DeleteUserStatus>(DeleteUserStatus.Pending);
    const navigate = useNavigate();
    const { id, token } = useParams<{ id: string; token: string }>();
    const [deleteUser] = useMutation(DELETE_USER_MUTATION);

    // Try to verify email when landing on this page
    useEffect(() => {
        deleteUser({ variables: { userID: id, token: token } }).then(() => {
            navigate('/userDeleted');
        }).catch((e) => {
            setStatus(DeleteUserStatus.Failed);
            console.log("Account deletion failed: ", e);
        });
    }, []);

    return (
        <div className="Home">
            <header className="Home-header">
                {status === DeleteUserStatus.Pending && (
                    <>
                        <h1>Removing account</h1>
                        <div className="loader"></div>
                    </>
                )}
                {status === DeleteUserStatus.Failed && (
                    <>
                        <h1>Account removal failed</h1>
                        <div className="error-indicator">X</div>
                    </>
                )}
            </header>
        </div>
    );
};
